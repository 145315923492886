<script>
import Layout from "../../layouts/main"
import PageHeader from "@/components/page-header"
import { required } from "vuelidate/lib/validators"
import Swal from "sweetalert2"

import { 
    getUserParkings,
    createUserParkings,
    getParking,
    editParking,
    deleteParking,
} from '@/services/parkings.service'
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  async mounted() {
    this.parkingData = await getUserParkings()
  },
  data() {
    return {
      title: "Mis Parqueos",
      items: [
        {
          text: "MaxParking"
        },
        {
          text: "Lotes",
          active: true
        }
      ],
      parkingData: [],
      parking: {
          name: "",
          address: "",
          open_hour: "",
          close_hour: "",
          establishment: ""
      },
      editing_id: "",
      submitted: false,
      showmodal: false,
      edit_showmodal: false,
      createError: "",
      isCreateError: false,
      editError: "",
      isEditError: false
    };
  },
  validations: {
    parking: {
        name:  { required },
        address: { required },
        open_hour: { required },
        close_hour: { required },
        establishment: { required },
    },
  },
  methods: {
    async create() {
      this.submitted = true
      this.isCreateError = false
      this.createError = ""
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      } else {
        const result = await createUserParkings(this.parking)
        if (result.status >= 400) {
            if (result.status === 400) {
                const errors = Object.values(result.errors)
                errors.forEach(error => this.createError += (this.createError === null) ? '': `${error[0]} <br/>` )
            } else {
                this.createError = result.errors.status
            }
            this.isCreateError = true
        } else {
            this.parkingData = await getUserParkings()
            this.showmodal = false
        }
      }

      this.submitted = false
      this.parking = {
        name: "",
        address: "",
        establishment: "",
        hourly_fee: "",
        day_fee: "",
        min_fee: "",
        open_hour: "",
        close_hour: "",
      }
    },
    async edit() {
        const parkingData = this.parking
        parkingData._id = this.editing_id

        const result = await editParking(this.parking)

        if (result.status >= 400) {
            if (result.status === 400) {
                const errors = Object.values(result.errors)
                errors.forEach(error => this.editError += (this.editError === null) ? '': `${error[0]} <br/>` )
            } else {
                this.editError = result.errors.status
            }
            this.isEditError = true
        } else {
            this.parkingData = await getUserParkings()
            this.edit_showmodal = false
        }
    },
    async deleteParking(id) {
      Swal.fire({
        title: "¿Esta seguro?",
        text: "Borrar el parqueo tambien desasigna a todos los operadores",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Si, borrar!",
        cancelButtonText: "Cancelar"
      }).then(async (result) => {
        if (result.value) {
            await deleteParking(id)
            this.parkingData = await getUserParkings()
            Swal.fire("Borrado!", "El parqueo ha sido eliminado.", "success");
        }
      });
    },
    hideEditModal() {
        this.parking = {
            name: "",
            address: "",
            establishment: "",
            hourly_fee: "",
            day_fee: "",
            min_fee: "",
            open_hour: "",
            close_hour: "",
        }
        this.edit_showmodal = false
    },
    async loadEdit(id) {
        this.editing_id = id
        const parking = await getParking(this.editing_id)
        this.parking = parking

        this.edit_showmodal = true
    },
    hideModal() {
        this.submitted = false
        this.showmodal = false
        this.parking = {
            name: "",
            address: "",
            establishment: "",
            hourly_fee: "",
            day_fee: "",
            min_fee: "",
            open_hour: "",
            close_hour: "",
        }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div>
              <a href="javascript:void(0);" class="btn btn-success mb-2" @click="showmodal = true">
                <i class="mdi mdi-plus mr-2"></i> Agregar Parqueo
              </a>
            </div>
            <div class="table-responsive mt-3">
              <table
                class="table table-centered datatable dt-responsive nowrap"
                style="border-collapse: collapse; border-spacing: 0; width: 100%;"
              >
                <thead class="thead-light">
                  <tr>
                    <th>Nombre</th>
                    <th>Direccion</th>
                    <th>Horario</th>
                    <th style="width: 120px;">Acción</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in parkingData" :key="index">
                    <td>{{item.name}}</td>
                    <td>{{item.address}}</td>
                    <td>{{item.open_hour}} - {{item.close_hour}}</td>
                    <td>
                      <a
                        @click="loadEdit(item._id)"
                        class="mr-3 text-primary"
                        v-b-tooltip.hover
                        title="Editar Información"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                        @click="deleteParking(item._id)"
                        class="text-danger"
                        v-b-tooltip.hover
                        title="Borrar"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    <!-- CREATE Modal -->
    <b-modal
      id="modal-1"
      v-model="showmodal"
      title="Agregar un parqueo"
      title-class="text-dark font-18"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
    >
        <b-alert
            v-model="isCreateError"
            class="mt-3"
            variant="danger"
            dismissible>
            <span v-html="createError"></span>
        </b-alert>
        <form @submit.prevent="create">
            <div class="form-group">
              <label for="name">Name</label>
              <input
                  id="name"
                  v-model="parking.name"
                  type="text"
                  class="form-control"
                  placeholder="Ingrese nombre del parqueo"
                  :class="{ 'is-invalid': submitted && $v.parking.name.$error }"
              />
              <div
                  v-if="submitted && !$v.parking.name.required"
                  class="invalid-feedback"
              >El nombre del parqueo es requerido</div>
            </div>
            <div class="form-group">
              <label for="address">Dirección</label>
              <input
                  id="direccion"
                  v-model="parking.address"
                  type="address"
                  name="address"
                  class="form-control"
                  placeholder="Ingresar dirección"
                  :class="{ 'is-invalid': submitted && $v.parking.address.$error }"
              />
              <div v-if="submitted && $v.parking.address.$error" class="invalid-feedback">
                  <span v-if="!$v.parking.address.required">La dirección del parqueo es requerida</span>
              </div>
            </div>
            <div class="form-group">
              <label for="address">Código De Establecimiento (SAT):</label>
              <input
                  id="establishment"
                  v-model="parking.establishment"
                  type="text"
                  name="establishment"
                  class="form-control"
                  placeholder="Código de establecimiento"
                  :class="{ 'is-invalid': submitted && $v.parking.establishment.$error }"
              />
              <div v-if="submitted && $v.parking.establishment.$error" class="invalid-feedback">
                <span v-if="!$v.parking.establishment.required">El código de establecimiento es requerido</span>
              </div>
            </div>
            <div class="form-group">
              <label for="position">Horario de apertura</label>
              <input
                  id="open"
                  v-model="parking.open_hour"
                  type="text"
                  class="form-control"
                  placeholder="Ingrese la hora de apertura del parqueo en formato ##:##"
                  :class="{ 'is-invalid': submitted && $v.parking.open_hour.$error }"
              />
              <div
                  v-if="submitted && !$v.parking.open_hour.required"
                  class="invalid-feedback"
              >La hora de apertura es requerida</div>
            </div>
            <div class="form-group">
              <label for="position">Horario de cierre</label>
              <input
                  id="close"
                  v-model="parking.close_hour"
                  type="text"
                  class="form-control"
                  placeholder="Ingrese la hora de cierre del parqueo en formato ##:##"
                  :class="{ 'is-invalid': submitted && $v.parking.close_hour.$error }"
              />
              <div
                  v-if="submitted && !$v.parking.close_hour.required"
                  class="invalid-feedback"
              >La hora de cierre es requerida</div>
            </div>
            <div class="text-right">
            <button type="submit" class="btn btn-success">Guardar</button>
            <b-button class="ml-1" variant="danger" @click="hideModal">Cancelar</b-button>
            </div>
        </form>
    </b-modal>
    <!-- end create modal -->

    <!-- EDIT Modal -->
    <b-modal
      id="modal-1"
      v-model="edit_showmodal"
      title="Editar un parqueo"
      title-class="text-dark font-18"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
    >
        <b-alert
            v-model="isEditError"
            class="mt-3"
            variant="danger"
            dismissible>
            <span v-html="editError"></span>
        </b-alert>
        <form @submit.prevent="edit">
            <div class="form-group">
              <input
                  v-model="editing_id"
                  type="hidden"
              />
              <label for="name">Name</label>
              <input
                  id="name"
                  v-model="parking.name"
                  type="text"
                  class="form-control"
                  placeholder="Ingrese nombre del parqueo"
                  :class="{ 'is-invalid': submitted && $v.parking.name.$error }"
              />
              <div
                  v-if="submitted && !$v.parking.name.required"
                  class="invalid-feedback"
              >El nombre del parqueo es requerido</div>
            </div>
            <div class="form-group">
              <label for="address">Dirección</label>
              <input
                  id="direccion"
                  v-model="parking.address"
                  type="address"
                  name="address"
                  class="form-control"
                  placeholder="Ingresar dirección"
                  :class="{ 'is-invalid': submitted && $v.parking.address.$error }"
              />
              <div v-if="submitted && $v.parking.address.$error" class="invalid-feedback">
                  <span v-if="!$v.parking.address.required">La dirección del parqueo es requerida</span>
              </div>
            </div>
            <div class="form-group">
              <label for="address">Código De Establecimiento (SAT):</label>
              <input
                  id="establishment"
                  v-model="parking.establishment"
                  type="text"
                  name="establishment"
                  class="form-control"
                  placeholder="Código de establecimiento"
                  :class="{ 'is-invalid': submitted && $v.parking.establishment.$error }"
              />
              <div v-if="submitted && $v.parking.establishment.$error" class="invalid-feedback">
                <span v-if="!$v.parking.establishment.required">El código de establecimiento es requerido</span>
              </div>
            </div>
            <div class="form-group">
              <label for="position">Horario de apertura</label>
              <input
                  id="open"
                  v-model="parking.open_hour"
                  type="text"
                  class="form-control"
                  placeholder="Ingrese la hora de apertura del parqueo en formato ##:##"
                  :class="{ 'is-invalid': submitted && $v.parking.open_hour.$error }"
              />
              <div
                  v-if="submitted && !$v.parking.open_hour.required"
                  class="invalid-feedback"
              >La hora de apertura es requerida</div>
            </div>
            <div class="form-group">
              <label for="position">Horario de cierre</label>
              <input
                  id="close"
                  v-model="parking.close_hour"
                  type="text"
                  class="form-control"
                  placeholder="Ingrese la hora de cierre del parqueo en formato ##:##"
                  :class="{ 'is-invalid': submitted && $v.parking.close_hour.$error }"
              />
              <div
                  v-if="submitted && !$v.parking.close_hour.required"
                  class="invalid-feedback"
              >La hora de cierre es requerida</div>
            </div>
            <div class="text-right">
            <button type="submit" class="btn btn-success">Save</button>
            <b-button class="ml-1" variant="danger" @click="hideEditModal">Cancelar</b-button>
            </div>
        </form>
    </b-modal>
    <!-- end edit modal -->
  </Layout>
</template>