const axios = require('axios')
const api_base = process.env.VUE_APP_API_BASE_URL

const userService = require('./user.service')

const getUserParkings = async () => {
    const me = await userService.me()
    const url = `${api_base}/v1/parkings/${me._id}`

    const parkings = await axios.get(url)
        .catch((error) => {
            if(error.response.status === 404) {
                return {
                    data: []
                }
            } else {
                console.log('ERROR GETTING PARKINGS')
                return {
                    data: error.response
                }
            }
        })
    
    return parkings.data
}

const createUserParkings = async (parking) => {
    const me = await userService.me()
    const url = `${api_base}/v1/parkings`

    parking.user_id = me._id
    console.log(parking)
    const result = await post(url, parking)
    return result
}

const getParking = async (id) => {
    const url = `${api_base}/v1/parkings/lot/${id}`

    const parking = await axios.get(url)
    .catch(() => {
        console.log('ERROR GETTING PARKING')
        return {
            data: {
                parking: {}
            }
        }
    })

    return parking.data
}

const editParking = async (parkingData) => {
    const url = `${api_base}/v1/parkings`

    const parking = await patch(url, parkingData)
        .catch(() => {
            console.log('ERROR UPDATING PARKING')
            return {
                data: {
                    parking: {}
                }
            }
        })

    return parking
}

const deleteParking = async (parking_id) => {
    const url = `${api_base}/v1/parkings`
    const me = await userService.me()

    const result = del(url, {
        data: {
            user_id: me._id,
            parking_id,
        }
    }).catch((error) => {
        console.log('ERROR DELETING PARKING')
        const retError = {
            errors: error.response.data
        }

        retError.status = error.response.status
        
        return retError
    })

    return result
}

// PRIVATES
const post = async (url, data) => {
    const result = await axios.post(url, data)
        .catch((error) => {
            const retError = {
                errors: error.response.data
            }

            retError.status = error.response.status
            
            return retError
        })

    return result
}

const patch = async (url, data) => {
    const result = await axios.patch(url, data)
        .catch((error) => {
            const retError = {
                errors: error.response.data
            }

            retError.status = error.response.status
            
            return retError
        })

    return result
}

const del = async (url, data) => {
    const result = await axios.delete(url, data)
        .catch((error) => {
            const retError = {
                errors: error.response.data
            }

            retError.status = error.response.status
            
            return retError
        })

    return result
}

module.exports = {
    getUserParkings,
    createUserParkings,
    getParking,
    editParking,
    deleteParking
}